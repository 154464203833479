<template>
  <div id="app">
    <div v-show="langType === 'en' && showTopBottom">
      <TopBar :newTitle="newTitle" :newId="newId" v-show="showType === 'mobile'"></TopBar>
      <webTopBar :newTitle="newTitle" :newId="newId" v-show="showType === 'pc'"></webTopBar>
    </div>
    <div v-show="langType === 'jp' && showTopBottom">
      <JapTopBar :newTitle="newTitle" :newId="newId" v-show="showType === 'mobile'"></JapTopBar>
      <JapWebTopBar :newTitle="newTitle" :newId="newId" v-show="showType === 'pc'"></JapWebTopBar>
    </div>

    <router-view :class="newTitle && showType === 'mobile'?'app-have-new':newTitle && showType === 'pc'? 'pc-have-new':
    !newTitle && showType === 'mobile'?'app-none-new':!newTitle && showType === 'pc'?'pc-none-new':''"></router-view>

    <div v-if="routerList.includes(nowRoute) && showBack && showType === 'mobile'" class="back-top" @click="backTop">
      <img src="@/assets/img/product/back-top.svg"/>
    </div>
    <div v-if="routerList.includes(nowRoute) && showBack && showType === 'pc'" class="web-back-top" @click="backTop">
      <img src="@/assets/img/product/back-top.svg"/>
    </div>

    <div v-show="langType === 'en' && showTopBottom">
      <BottomData v-show="showType === 'mobile'"></BottomData>
      <webBottomBar v-show="showType === 'pc'"></webBottomBar>
    </div>
    <div v-show="langType === 'jp' && showTopBottom">
      <JapBottomData v-show="showType === 'mobile'"></JapBottomData>
      <JapWebBottomBar v-show="showType === 'pc'"></JapWebBottomBar>
    </div>

  </div>
</template>

<script>
import { insert } from "@/api/insert.js";
import {updateList} from "@/api/updates";
import {isNowMonth} from "@/utils/time";
export default {
  components: {
    TopBar: () => import('./components/en/TopBar.vue'),
    BottomData: () => import('./components/en/BottomData.vue'),
    webTopBar: () => import('./components/en/webTopBar.vue'),
    webBottomBar: () => import('./components/en/webBottomBar.vue'),

    JapTopBar: () => import('./components/jap/TopBar.vue'),
    JapBottomData: () => import('./components/jap/BottomData.vue'),
    JapWebTopBar: () => import('./components/jap/webTopBar.vue'),
    JapWebBottomBar: () => import('./components/jap/webBottomBar.vue')
  },
  name: 'App',
  watch:{
    $route:{
      handler(to,from){
        this.nowRoute = to.path
        if(this.nowRoute){
          if(this.nowRoute.startsWith('/file')){
            this.showTopBottom = false
          }else {
            this.showTopBottom = true
          }
          if(this.nowRoute.startsWith('/jp')){
            this.langType = 'jp'
          }else {
            this.langType = 'en'
          }
        }
      },
      immediate:true,//第一次就执行
    },
    "langType"(nV,oV){
      if(nV === 'en'){
        this.getUpdateList('en')
      }else {
        this.getUpdateList('ja')
      }
    }
  },
  data(){
    return{
      showBack:false,
      nowRoute:this.$route.fullPath,
      routerList:['/voice'],

      showType:'',
      langType:'en',

      showTopBottom:true,

      newTitle: "",
      newId:null,
      page: 1,
      size: 1,
      isFirstOpen:0,
    }
  },
  methods:{
    handleScroll(){
      const scrollY = document.documentElement.scrollTop;
      if(this.showType === 'mobile'){
        if(scrollY >= 500){
          this.showBack = true
        }else {
          this.showBack = false
        }
      }else {
        if(scrollY >= 700){
          this.showBack = true
        }else {
          this.showBack = false
        }
      }
    },
    backTop(){
      if (this.showType === 'mobile') {
        let top = document.documentElement.scrollTop || document.body.scrollTop
        const timeTop = setInterval(() => {
          document.body.scrollTop = document.documentElement.scrollTop = top -= 50
          if (top <= 0) {
            clearInterval(timeTop)
          }
        }, 10)
      }else {
        let top = document.documentElement.scrollTop || document.body.scrollTop
        const timeTop = setInterval(() => {
          document.body.scrollTop = document.documentElement.scrollTop = top -= 180
          if (top <= 0) {
            clearInterval(timeTop)
          }
        }, 10)
      }
    },

    handleResize() {
      if (window.innerWidth <= 768) {
        this.showType = 'mobile';
      } else {
        this.showType = 'pc';
      }
    },

    getUpdateList(lang) {
      let param = {
        page: this.page,
        size: this.size,
        lang:lang,
        type:'post'
      }
      updateList(param).then(res => {
        if (res.success) {
          if(isNowMonth(res.rows.list[0].year,res.rows.list[0].data[0].month,res.rows.list[0].data[0].day)){
            this.newTitle = res.rows.list[0].data[0].title
            this.newId = res.rows.list[0].data[0].id
          }
        } else {
          this.$toast(res.msg)
        }
      }).catch(error => {
      })
    }
  },
  mounted() {
    this.$router.afterEach((to, from) => {
      this.$nextTick(() => {
        if(this.isFirstOpen === 0){
          if (!this.$route.query.addressID) {
            if(this.$route.path === '/' || this.$route.path === '/jp'){
              this.$route.query.addressID = "0"
            }else {
              return
            }
          }
          insert({ source: this.$route.query.addressID }).then(res => {
            this.isFirstOpen += 1
            window.history.pushState({}, document.title, window.location.href.split('?')[0]);
          }).catch(error =>{
            window.history.pushState({}, document.title, window.location.href.split('?')[0]);
          })
        }
      })
    });
    this.getUpdateList('en')
    window.addEventListener('scroll', this.handleScroll)
  },

  created() {
    this.handleResize(); // 初始化时执行一次，根据屏幕宽度设置初始组件
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style src="./css/common.css"></style>
<style lang="scss">
html, #app{
  overflow-y: scroll; /* 显示纵向滚动条 */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.html, #app::-webkit-scrollbar {
  display: none; /* 隐藏Chrome和Safari的滚动条 */
}
#app {
  width: 100%;
  height: 100%;
  font-family: Arial !important;
  box-sizing: border-box;
}

.app-have-new{
  margin-top: 96px !important;
}
.app-none-new{
  margin-top: 54px!important;

}
.pc-have-new{
  margin-top: calc(106/var(--base-rem-value)* 1rem)!important;
}
.pc-none-new{
  margin-top: calc(70/var(--base-rem-value)* 1rem)!important;
}

@font-face {
  font-family: 'Arial';
  src: url('./assets/font/Arial.ttf');
}

@font-face {
  font-family: Meiryo;
  src: url('./assets/font/MEIRYO.TTC');
}

@font-face {
  font-family: Meiryo B;
  src: url('./assets/font/MEIRYOB.TTC');
}

@font-face {
  font-family: 'ArialBold';
  src: url('./assets/font/Arial Bold.ttf');
}
.back-top{
  width: 44px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgba(123, 123, 123, 0.3);
  position: fixed;
  right: 8px;
  bottom: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.web-back-top{
  width: calc(60/var(--base-rem-value)* 1rem);
  height: calc(60/var(--base-rem-value)* 1rem);
  background: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 0 calc(8/var(--base-rem-value)* 1rem) 0 rgba(123, 123, 123, 0.3);
  position: fixed;
  right: calc(100/var(--base-rem-value)* 1rem);;
  bottom: calc(126/var(--base-rem-value)* 1rem);;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter); //使用插件

// 在路由内重写push或replace方法（解决控制台报错）
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch(err => err)
}

//引入路由组件
//配置路由
export default new VueRouter({
  mode: 'history',
  routes: [
    { path: "/", name: 'Home', component: () => import("@/pages/en/Home") },
    { path: "/about", component: () => import("@/pages/en/About") },
    { path: "/life", component: () => import("@/pages/en/Life") },
    { path: "/product", name: 'productPage', component: () => import("@/pages/en/Product") },
    { path: "/founders", name: 'Founders', component: () => import("@/pages/en/Founders") },
    { path: "/news", name: 'Updates', component: () => import("@/pages/en/Updates") },
    { path: '/newsDetail/:id', name: 'UpdateDetail', component: () => import("@/pages/en/Updates/detail")},
    { path: "/voice", name: 'voicePage', component: () => import("@/pages/en/Product/voice") },
    { path: "/contact", name: 'Contact', component: () => import("@/pages/en/Contacts") },
    { path: "/faqs", name: 'Faqs', component: () => import("@/pages/en/Faqs")},
    { path: "/column", name: 'Column', component:()=> import("@/pages/en/Column") },
    { path: "/columnDetail/:id", name: 'ColumnDetail', component: () => import("@/pages/en/Column/detail")},
    { path: "/hotline", name: 'Hotline', component: () => import("@/pages/en/other/hotlineIndex")},
    { path: "/privacy", name: 'Privacy', component: () => import("@/pages/en/other/privacyIndex")},

    { path: "/file/:id", name: 'File', component: () => import("@/pages/en/other/fileDownload")},


    { path: "/jp", name: 'JapHome', component: () => import("@/pages/jap/Home") },
    { path: "/jp/about", component: () => import("@/pages/jap/About") },
    { path: "/jp/awardDetail", name: 'JapAwardDetail', component: () => import("@/pages/jap/About/awardDetail") },
    { path: "/jp/life", component: () => import("@/pages/jap/Life") },
    { path: "/jp/product", name: 'JapProductPage', component: () => import("@/pages/jap/Product") },
    { path: "/jp/founders", name: 'JapFounders', component: () => import("@/pages/jap/Founders") },
    { path: "/jp/news", name: 'JapUpdates', component: () => import("@/pages/jap/Updates") },
    { path: '/jp/newsDetail/:id', name: 'JapUpdateDetail', component: () => import("@/pages/jap/Updates/detail")},
    { path: "/jp/voice", name: 'JapVoicePage', component: () => import("@/pages/jap/Product/voice") },
    { path: "/jp/contact", name: 'JapContact', component: () => import("@/pages/jap/Contacts") },
    { path: "/jp/faqs", name: 'JapFaqs', component: () => import("@/pages/jap/Faqs")},
    { path: "/jp/column", name: 'JapColumn', component:()=> import("@/pages/jap/Column") },
    { path: "/jp/columnDetail/:id", name: 'JapColumnDetail', component: () => import("@/pages/jap/Column/detail")},
    { path: "/jp/hotline", name: 'JapHotline', component: () => import("@/pages/jap/other/hotlineIndex")},
    { path: "/jp/privacy", name: 'JapPrivacy', component: () => import("@/pages/jap/other/privacyIndex")},
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }; // 将页面滚动条置顶
  }
});
